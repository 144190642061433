"use client";

import React from "react";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "../ui/tabs";
import { Button } from "../ui/button";
import { Select } from "@radix-ui/react-select";
import Link from "next/link";
import Image from "next/image";
import { useState } from "react";
import bedsicon from '@/public/images/about-us/beds-icon.png'
import doctoricon from '@/public/images/about-us/doctors-icon.png'
import nurseicon from '@/public/images/about-us/nurses-icon.png'
import navigationicon from '@/public/images/about-us/navigation-icon.png'
import callicon from '@/public/images/about-us/call-icon.png'
import malviyanagar from '@/public/images/about-us/malvia-nagar-mobile.png'
import CustomLink from "../common/custom-link";

function OurLocations(props) {
    const [selectedHospital, setSelectedHospital] = useState(
        props.data.data[0]
    );


    const handleSelectChange = (event) => {
        const selectedId = parseInt(event.target.value);
        const selectedHospitalData = props.data.data.find(
            (item) => item.id === selectedId
        );
        setSelectedHospital(selectedHospitalData);
    };


    return (
        <div className="custom-container mx-auto bg-white lg:p-0 lg:mb-20 mb-10  mt-12 lg:mt-0" id="sec_locations">
            <h2 className="text-primaryBlue header1 mt-2 mb-5 text-center md:text-left"> {props.loc.title} </h2>
            {/* desktop section starts */}

            <Tabs defaultValue={props.data.data[0].attributes?.locationCity} className="lg:block hidden" >
                <TabsList className="bg-white flex justify-between">
                    {props.data.data?.map((e, i) => (
                        <TabsTrigger value={e.attributes?.locationCity} className=" text-primaryBlue data-[state=active]:bg-primaryBlue data-[state=active]:text-primaryWhite rounded-3xl px-6 text-lg hover:bg-primaryBlue hover:text-primaryWhite transition duration-500" key={i} > {e.attributes?.locationCity} </TabsTrigger>
                    ))}
                </TabsList>

                {props.data.data?.map((e, i) => (
                    <TabsContent value={e.attributes?.locationCity} key={i}>
                        <div className="relative rounded-3xl">
                            <Image src={`${process.env.NEXT_PUBLIC_BE_URL}${e?.attributes?.locationImage.data.attributes?.url}`} width={1170} height={518} className="relative w-full h-auto md:h-[480px] xl:h-full object-cover object-right max-h-[520px] rounded-3xl" alt="malviya nagar" title="malviya nagar" />
                            <div className="absolute top-0 left-0 bg-gradient-to-r from-primaryBlue from-30% via-70% to-99% via-primaryBlue w-6/12 h-full pt-12 pb-16 px-10 rounded-3xl rounded-r-none">
                                <h2 className="text-white header1 mb-2"> {props.loc.apex} </h2>
                                <p className="text-white p-text"> {e.attributes?.locationCity} </p>
                                <p className="text-white py-4 p-text lg:w-4/5 h-[100px]"> {e.attributes?.locationAddress} </p>
                                <ul className="list-none flex pb-16 lg:pb-10 xl:pb-12">
                                    <li className="flex pr-1 md:pr-2 items-center justify-center">
                                        <Image src={bedsicon} width={47} height={47} alt="beds icon" title="beds icon" />
                                    </li>
                                    <li className="pr-6">
                                        <h6 className="text-white header-sub"> { e.attributes?.totalBedsAvailable }+ </h6>
                                        <p className="text-white p-text"> {props.loc.bed} </p>
                                    </li>

                                    <li className="flex pr-1 md:pr-2 items-center justify-center">
                                        <Image src={doctoricon} width={36} height={41} alt="doctors icon" title="doctors icon" />
                                    </li>
                                    <li className="pr-6">
                                        <h6 className="text-white header-sub"> { e.attributes ?.totalDoctorsAvailable }+ </h6>
                                        <p className="text-white p-text"> {props.loc.doctor} </p>
                                    </li>

                                    <li className="flex pr-1 md:pr-2 items-center justify-center">
                                        <Image src={nurseicon} width={36} height={43} alt="nurses icon" title="nurses icon" />
                                    </li>
                                    <li className="pr-6">
                                        <h6 className="text-white header-sub"> {e.attributes?.totalNursesAvailable}+ </h6>
                                        <p className="text-white p-text"> {props.loc.nurse} </p>
                                    </li>
                                </ul>
                                <ul className="flex pb-4">
                                    
                                    <li className="border-white rounded-full border-2 p-2 mr-6">
                                        <Link href={e.attributes?.locationGMapLink} target="_blank" className="location_mapicon">
                                            <Image src={navigationicon} className="locations-navigation" width={24} height={24} alt="navigation icon" title="navigation icon" />
                                        </Link>
                                    </li>

                                    <li className="border-white rounded-full border-2 p-2 mr-6">
                                        <a href={`tel:${e.attributes?.callUs}`} className="location_callicon">
                                            <Image src={callicon} className="locations-call" width={24} height={24} alt="call icon" title="call icon" />
                                        </a>
                                    </li>
                                    <li className="border-white rounded-full border-2 p-2 hidden">
                                        <Image src={navigationicon} width={24} height={24} alt="navigation icon" title="navigation icon" />
                                        <p className="text-white font-light ml-2"> VIRTUAL TOUR </p>
                                    </li>
                                </ul>
                                <CustomLink lang={props.lang} href={`/book/book?location=${e.attributes?.locationCity}`} className="bg-primaryWhite block w-fit text-primaryBlue rounded-full border hover:bg-buttonbg hover:border-primaryWhite hover:text-primaryWhite uppercase px-8 py-3 mt-6 mb-6 relative transition-all duration-700 p-text locations_bookbtnhome"> {props.loc.book} </CustomLink>
                            </div>
                        </div>
                    </TabsContent>
                ))}
            </Tabs>

            {/* desktop section ends */}

            {/* mobile section starts */}
            <div className="block lg:hidden">
                <select onChange={handleSelectChange} value={selectedHospital.id} className="border border-primaryBlue text-primaryGreyText uppercase py-2 w-full rounded-3xl mb-5 pl-3 pr-4 custom-arrow" >
                    {props.data.data?.map((e, i) => (
                        <option className="pr-4" key={e.id} value={e.id}> {e.attributes?.locationCity} </option>
                    ))}
                </select>

                <div className="mainclass rounded-t-full">
                    <div className="rounded-3xl">
                        <Image src={malviyanagar} width={469} height={155} alt="malviya nagar" title="malviya nagar" className="rounded-t-3xl w-full" />
                    </div>
                    <div className="p-2 rounded-b-3xl bg-gradient-to-t bg-primaryBlue">
                        <h2 className="text-white mb-2 header1"> {props.loc.apex} </h2>
                        <p className="text-white p-text">
                            {selectedHospital.attributes?.locationCity}
                        </p>
                        <p className="text-white py-4 p-text h-20 ">
                            {selectedHospital.attributes?.locationAddress}
                        </p>
                        <ul className="list-none flex pb-4 justify-around">
                            <li className="flex pr-1 md:pr-2 items-center justify-center">
                                <Image src={bedsicon} width={47} height={47} alt="beds icon" title="beds icon" />
                            </li>
                            <li>
                                <h6 className="text-white font-bold"> { selectedHospital.attributes ?.totalBedsAvailable } + </h6>
                                <p className="text-white p-text">{props.loc.bed}</p>
                            </li>

                            <li className="flex pr-1 md:pr-2 items-center justify-center">
                                <Image src={doctoricon} width={36} height={41} alt="doctors icon" title="doctors icon" />
                            </li>
                            <li className="mr-1.5">
                                <h6 className="text-white font-bold"> { selectedHospital.attributes ?.totalDoctorsAvailable } + </h6>
                                <p className="text-white p-text">{props.loc.doctor}</p>
                            </li>

                            <li className="flex pr-1 md:pr-2 items-center justify-center">
                                <Image src={nurseicon} width={36} height={43} alt="nurses icon" title="nurses icon" />
                            </li>
                            <li>
                                <h6 className="text-white font-bold"> { selectedHospital.attributes ?.totalNursesAvailable } </h6>
                                <p className="text-white p-text">{props.loc.nurse}</p>
                            </li>
                        </ul>

                        <ul className="flex pb-4 justify-center">
                            <li className="border-white rounded-full border-2 p-2 mr-3">
                               <Link href={selectedHospital.attributes.locationGMapLink} target="_blank" className="location_mapicon">
                                        <Image src={navigationicon} width={24} height={24} alt="navigation icon" title="navigation icon" />
                                </Link>
                            </li>
                            <li className="border-white rounded-full border-2 p-2 mr-3">
                                <a href="tel:{selectedHospital.attributes.callUs}" className="location_callicon">
                                    <Image src={callicon} width={24} height={24} alt="call icon" title="call icon" />
                                </a>
                            </li>
                            <li className="border-white rounded-full border-2 p-2 hidden">
                                <Image src={navigationicon} width={24} height={24} alt="navigation icon" title="navigation icon" />
                                <p className="text-white font-light ml-2"> VIRTUAL TOUR </p>
                            </li>
                        </ul>
                        <div className="text-center">
                            <CustomLink lang={props.lang} href={`/book/book?location=${selectedHospital.attributes?.locationCity}`} className="bg-primaryWhite block w-full text-primaryBlue rounded-full border hover:bg-buttonbg hover:border-primaryWhite hover:text-primaryWhite uppercase px-8 py-3 md:mt-6 md:mb-6 my-2 relative transition-all duration-700 p-text locations_bookbtnhome"> {props.loc.book} </CustomLink>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default OurLocations;
